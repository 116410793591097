import { createContext } from 'react'
import { noop } from 'lodash'

import { ExtraServiceCheckoutModal } from 'constants/extra-service'
import { BumpableItemModel } from 'types/models'

export type BumpCheckoutContextType = {
  activeModal: ExtraServiceCheckoutModal
  selectedItemIds: Array<number>
  setActiveModal: (modal: ExtraServiceCheckoutModal) => void
  setSelectedItemIds: (ids: Array<number>) => void
  updateItemThumbnailsById: (items: Array<BumpableItemModel>) => void
  getSelectedItemThumbnails: () => Array<BumpableItemModel>
}

export const initialValues: BumpCheckoutContextType = {
  activeModal: ExtraServiceCheckoutModal.None,
  selectedItemIds: [],
  setActiveModal: noop,
  setSelectedItemIds: noop,
  updateItemThumbnailsById: noop,
  getSelectedItemThumbnails: () => [],
}

const BumpCheckoutContext = createContext<BumpCheckoutContextType>(initialValues)

export default BumpCheckoutContext
