import { getLocalStorageItem, setLocalStorageItem } from 'libs/utils/localStorage'
import { ExtraServiceCheckoutModal } from 'constants/extra-service'

import { BumpableItemModel } from 'types/models'

import useBumpCheckoutContext from './useBumpCheckoutContext'

const VALUE_PROPOSITION_SHOWN_KEY = 'push_up_value_proposition_shown'

const useStartBumpCheckout = () => {
  const { setSelectedItemIds, updateItemThumbnailsById, setActiveModal } = useBumpCheckoutContext()

  const startCheckout = (selectedItem?: BumpableItemModel) => {
    if (selectedItem) {
      setSelectedItemIds([selectedItem.id])
      updateItemThumbnailsById([selectedItem])
    }

    const isValuePropositionSeen = getLocalStorageItem(VALUE_PROPOSITION_SHOWN_KEY) === 'true'

    if (!isValuePropositionSeen) {
      setLocalStorageItem(VALUE_PROPOSITION_SHOWN_KEY, 'true')
      setActiveModal(ExtraServiceCheckoutModal.ValueProposition)

      return
    }

    setActiveModal(ExtraServiceCheckoutModal.OrderReview)
  }

  return { startCheckout }
}

export default useStartBumpCheckout
