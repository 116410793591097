import { ItemThumbnailSize } from 'constants/images'
import { ItemPhotoDto } from 'types/dtos'
import { BumpableItemModel, ItemPhotoModel, ProductItemModel } from 'types/models'

export const getBumpableItemThumbnailFromDto = (
  photos: Array<ItemPhotoDto> | undefined,
  size = ItemThumbnailSize.Medium,
) => {
  return (
    photos?.find(photo => photo.is_main)?.thumbnails?.find(thumb => thumb.type === size)?.url ||
    null
  )
}

export const getBumpableItemThumbnailFromModel = (
  photos: Array<ItemPhotoModel> | undefined,
  size = ItemThumbnailSize.Medium,
) => {
  return (
    photos?.find(photo => photo.isMain)?.thumbnails?.find(thumb => thumb.type === size)?.url || null
  )
}

export const getBumpableItemDataFromModel = (item: ProductItemModel): BumpableItemModel => {
  return {
    id: item.id,
    title: item.title,
    thumbnail: getBumpableItemThumbnailFromModel(item.photos),
  }
}
