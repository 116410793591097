'use client'

import { ReactNode, useCallback, useMemo, useState } from 'react'

import { ExtraServiceCheckoutModal } from 'constants/extra-service'
import { BumpableItemModel } from 'types/models'

import BumpCheckoutContext from './BumpCheckoutContext'

type Props = {
  children: ReactNode
}

const BumpCheckoutProvider = ({ children }: Props) => {
  const [activeModal, setActiveModal] = useState<ExtraServiceCheckoutModal>(
    ExtraServiceCheckoutModal.None,
  )
  const [selectedItemIds, setSelectedItemIds] = useState<Array<number>>([])
  const [itemThumbnailsById, setItemThumbnailsById] = useState<Record<number, BumpableItemModel>>(
    {},
  )

  const updateItemThumbnailsById = useCallback((items: Array<BumpableItemModel>) => {
    const newItems: Record<number, BumpableItemModel> = {}
    items.forEach(item => {
      newItems[item.id] = item
    })

    setItemThumbnailsById(newItems)
  }, [])

  const getSelectedItemThumbnails = useCallback(() => {
    return Object.keys(itemThumbnailsById).length
      ? selectedItemIds.map(id => itemThumbnailsById[id]).filter(Boolean)
      : []
  }, [itemThumbnailsById, selectedItemIds])

  const value = useMemo(() => {
    return {
      activeModal,
      selectedItemIds,
      setActiveModal,
      setSelectedItemIds,
      updateItemThumbnailsById,
      getSelectedItemThumbnails,
    }
  }, [
    activeModal,
    selectedItemIds,
    setActiveModal,
    setSelectedItemIds,
    updateItemThumbnailsById,
    getSelectedItemThumbnails,
  ])

  return <BumpCheckoutContext.Provider value={value}>{children}</BumpCheckoutContext.Provider>
}

export default BumpCheckoutProvider
