'use client'

import { useEffect, useState } from 'react'

import { Dialog } from '@vinted/web-ui'

import { urlWithParams } from 'libs/utils/url'
import { navigateToPage } from 'libs/utils/window'
import { navigateToSingleCheckout } from 'libs/utils/checkout'
import { viewSingleCheckoutEvent } from 'libs/common/event-tracker/events'

import { initiateSingleCheckout } from 'data/api'

import {
  ExtraServiceOrderType,
  ExtraServiceCheckoutModal,
  CheckoutOrderTypeMap,
} from 'constants/extra-service'
import { BUMP_MULTIPLE_ITEM_SELECTION_URL } from 'constants/routes'
import { UiState } from 'constants/ui'

import GenericErrorModal from 'pages/SingleCheckout/components/GenericErrorModal'
import { PromoAfterItemUpload } from 'pages/Profile/containers/PromoAfterItemUploadContext'

import ContentLoader from 'components/ContentLoader'
import useBumpCheckoutContext from 'hooks/useBumpCheckoutContext'

import useTracking from 'hooks/useTracking/useTracking'

import usePromoAfterItemUploadContext from 'pages/Profile/hooks/usePromoAfterItemUploadContext'

import useBumpOrder from './hooks/useBumpOrder'
import useBumpOptions from './hooks/useBumpOptions'
import BumpSelectionConfirmationModal from './BumpSelectionConfirmationModal'
import BumpValuePropositionModal from './BumpValuePropositionModal'
import BumpOrderErrorModal from './BumpOrderErrorModal'

type Props = {
  isInitiatedFromMultiSelect?: boolean
}

const BumpCheckout = ({ isInitiatedFromMultiSelect = false }: Props) => {
  const { track } = useTracking()
  const { selectedItemIds, activeModal, setActiveModal, setSelectedItemIds } =
    useBumpCheckoutContext()

  const { uploadedItemId, currentPromotion, setCurrentPromotion } = usePromoAfterItemUploadContext()

  const [uiState, setUiState] = useState(UiState.Idle)

  const isValuePropositionModalActive = activeModal === ExtraServiceCheckoutModal.ValueProposition

  const { order, isOrderLoading, orderError, prepareBumpOrder } = useBumpOrder()

  const {
    bumpOptions,
    isBumpOptionsLoading,
    bumpOptionsError,
    selectedBumpOption,
    updateSelectedBumpOption,
  } = useBumpOptions()

  useEffect(
    function openPrecheckoutAfterUploadForm() {
      if (currentPromotion !== PromoAfterItemUpload.BumpCheckout) return
      if (!uploadedItemId) return

      setSelectedItemIds([uploadedItemId])
      setActiveModal(ExtraServiceCheckoutModal.OrderReview)
      setCurrentPromotion(PromoAfterItemUpload.Empty)
    },
    [currentPromotion, uploadedItemId, setSelectedItemIds, setActiveModal, setCurrentPromotion],
  )

  useEffect(
    function setErrorModal() {
      if (!orderError) return

      setUiState(UiState.Failure)

      setActiveModal(ExtraServiceCheckoutModal.GenericErrorModal)
    },
    [orderError, setActiveModal],
  )

  useEffect(() => {
    const orderId = order?.id
    const orderType = CheckoutOrderTypeMap[ExtraServiceOrderType.PushUp]

    if (!orderId) return

    track(
      viewSingleCheckoutEvent({
        orderType,
        checkoutId: null,
        orderId: orderId.toString(),
      }),
    )

    const fetchCheckoutId = async () => {
      const response = await initiateSingleCheckout({
        id: orderId.toString(),
        type: orderType,
      })

      if ('errors' in response) {
        setUiState(UiState.Failure)

        setActiveModal(ExtraServiceCheckoutModal.GenericErrorModal)

        return
      }

      navigateToSingleCheckout(response.checkout.id, orderId, orderType)
    }

    fetchCheckoutId()
  }, [order, track, setActiveModal])

  function openSetupModal() {
    setActiveModal(ExtraServiceCheckoutModal.OrderReview)
  }

  function openValuePropositionModal() {
    setActiveModal(ExtraServiceCheckoutModal.ValueProposition)
  }

  function goToBumpPage() {
    navigateToPage(urlWithParams(BUMP_MULTIPLE_ITEM_SELECTION_URL, { item_ids: selectedItemIds }))
  }

  function closeCheckoutModal() {
    setActiveModal(ExtraServiceCheckoutModal.None)
  }

  function handleConfirmSelection() {
    if (!selectedBumpOption) return

    prepareBumpOrder(selectedItemIds, selectedBumpOption.days, selectedBumpOption.international)

    setUiState(UiState.Pending)
  }
  if (bumpOptionsError) return <BumpOrderErrorModal bumpOptionsError={bumpOptionsError} />

  if (uiState === UiState.Pending) {
    return (
      <Dialog show>
        <ContentLoader testId="bump-checkout-type-loader" />
      </Dialog>
    )
  }

  return (
    <>
      <BumpSelectionConfirmationModal
        show={activeModal === ExtraServiceCheckoutModal.OrderReview}
        isOrderLoading={isOrderLoading}
        bumpOptions={bumpOptions}
        isBumpOptionsLoading={isBumpOptionsLoading}
        selectedOption={selectedBumpOption}
        onAddButtonClick={isInitiatedFromMultiSelect ? closeCheckoutModal : goToBumpPage}
        onSelectedOptionChange={updateSelectedBumpOption}
        onConfirm={handleConfirmSelection}
        onCancel={closeCheckoutModal}
        onHelpButtonClick={openValuePropositionModal}
      />
      <BumpValuePropositionModal
        show={isValuePropositionModalActive}
        hasLocalOptions={bumpOptions?.hasLocalOptions}
        onCloseOrConfirm={openSetupModal}
      />
      <GenericErrorModal
        isShown={activeModal === ExtraServiceCheckoutModal.GenericErrorModal}
        onClose={closeCheckoutModal}
      />
    </>
  )
}

export default BumpCheckout
