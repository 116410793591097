import { useCallback, useEffect, useState } from 'react'

import { getBumpOptions } from 'data/api'
import { transformBumpOptionsResponse } from 'data/api/transformers/response'

import { ExtraServiceCheckoutModal } from 'constants/extra-service'

import useFetch from 'hooks/useFetch'
import { BumpOptionModel } from 'types/models'
import useBumpCheckoutContext from 'hooks/useBumpCheckoutContext'

const useBumpOptions = () => {
  const { selectedItemIds, activeModal } = useBumpCheckoutContext()

  const [selectedBumpOption, setSelectedBumpOption] = useState<BumpOptionModel | null>(null)
  const [isOptionsDataStale, setIsOptionsDataStale] = useState(false)

  const {
    fetch: fetchBumpOptions,
    transformedData: bumpOptions,
    isLoading: isBumpOptionsLoading,
    error: bumpOptionsError,
  } = useFetch(getBumpOptions, transformBumpOptionsResponse)

  const updateSelectedBumpOption = useCallback(
    (optionsData: BumpOptionModel) => {
      setSelectedBumpOption(optionsData)
    },
    [setSelectedBumpOption],
  )

  useEffect(() => {
    setIsOptionsDataStale(true)
  }, [selectedItemIds])

  useEffect(() => {
    if (activeModal === ExtraServiceCheckoutModal.None) return
    if (!isOptionsDataStale) return
    if (!selectedItemIds.length) return
    fetchBumpOptions({ itemIds: selectedItemIds })
    setIsOptionsDataStale(false)
  }, [fetchBumpOptions, selectedItemIds, isOptionsDataStale, activeModal])

  useEffect(() => {
    if (!bumpOptions?.options.length) return
    const defaultOption =
      bumpOptions.options.find(option => option.isDefault) || bumpOptions.options[0]!

    updateSelectedBumpOption(defaultOption)
  }, [bumpOptions, updateSelectedBumpOption])

  return {
    bumpOptions,
    isBumpOptionsLoading,
    bumpOptionsError,
    selectedBumpOption,
    updateSelectedBumpOption,
  }
}

export default useBumpOptions
