import { prepareBumpOrder as prepareBumpOrderRequest } from 'data/api'
import { transformPrepareBumpOrderResponse } from 'data/api/transformers/response'

import useFetch from 'hooks/useFetch'

const useBumpOrder = () => {
  const {
    fetch: prepareBumpOrder,
    transformedData: preparedOrder,
    isLoading: isOrderPrepareLoading,
    error: prepareOrderError,
  } = useFetch(prepareBumpOrderRequest, transformPrepareBumpOrderResponse)

  return {
    order: preparedOrder,
    isOrderLoading: isOrderPrepareLoading,
    orderError: prepareOrderError,
    prepareBumpOrder,
  }
}

export default useBumpOrder
